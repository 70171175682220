@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
/*===== GOOGLE FONTS =====*/
/*===== VARIABLES CSS =====*/
:root{
  --header-height: 3rem;
  --font-semi: 600;
}

/*===== Colores =====*/
:root{
  --first-color: #4070F4;
  --second-color: #0E2431;
}

/*===== Fuente y tipografia =====*/
:root{
  --body-font: 'Poppins', sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
}
@media screen and (min-width: 768px){
  :root{
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1rem;
  }
}

/*===== Margenes =====*/
:root{
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
}

/*===== z index =====*/
:root{
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*===== BASE =====*/
*,::before,::after{
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body{
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  color: #0E2431;
  color: var(--second-color);
}
h1,h2,p{
  margin: 0;
}
ul{
  margin: 0;
  padding: 0;
  list-style: none;
}
a{
  text-decoration: none;
  -webkit-text-decoration-style: none;
          text-decoration-style: none;
  color: inherit;
}
img{
  max-width: 100%;
  height: auto;
  display: block;
}

/*===== CLASS CSS ===== */
.section-title{
  position: relative;
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  color: #4070F4;
  color: var(--first-color);
  margin-top: 1rem;
  margin-top: var(--mb-2);
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
  text-align: center;
}

.section-subtitle {
  position: relative;
  text-align: center;
  margin-top: 1rem;
  margin-top: var(--mb-2);
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}

.section-title::after{
  position: absolute;
  content: "";
  width: 64px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  background-color: #4070F4;
  background-color: var(--first-color);
}
.section{
  padding-top: 3rem;
  padding-bottom: 2rem;
}

/*===== LAYOUT =====*/

.bd-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 1.5rem;

}

.bd-grid{
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-flex{
  display: flex;
  flex-direction: column;
}

.l-header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  box-shadow: 0 1px 4px rgba(146,161,176,.15);
}

.divider {
  display: none;
}

/*===== NAV =====*/
.nav{
  height: 3rem;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-weight: var(--font-semi);
}
@media screen and (max-width: 768px){
  .nav__menu{
    position: fixed;
    top: 3rem;
    top: var(--header-height);
    right: -100%;
    width: 80%;
    height: 100%;
    padding: 2rem;
    background-color: #0E2431;
    background-color: var(--second-color);
    transition: .5s;
  }
}
.nav__item{
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
  color: #fff;
}
.nav__link{
  position: relative;
}

.navbar__icons {
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
}


.nav__link:hover{
  position: relative;
}
.nav__link:hover::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 0.18rem;
  left: 0;
  top: 2rem;
  background-color: #4070F4;
  background-color: var(--first-color);
}
.nav__toggle{
  color: #0E2431;
  color: var(--second-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.active::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 0.18rem;
  left: 0;
  top: 2rem;
  background-color: #4070F4;
  background-color: var(--first-color);
}

/*=== Show menu ===*/
.show{
  right: 0;
}

/*===== HOME =====*/

.home {
  height: calc(100vh - 3rem);
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.blog {
  min-height: calc(100vh);
}

.certs {
  min-height: calc(100vh);
}


.home__data{
  align-self: center;
}
.home__title{
  font-size: 2rem;
  font-size: var(--big-font-size);
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-5);
}
.home__title-color{
  color: #4070F4;
  color: var(--first-color);
}
.home__social{
  display: flex;
  flex-direction: column;
}
.home__social-icon{
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 1rem;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: #0E2431;
  color: var(--second-color);
}
.home__social-icon:hover{
  color: #4070F4;
  color: var(--first-color);
}
.home__img{
  position: absolute;
  right: 2%;
  bottom: 2%;
  width: 70%;
}

/*BUTTONS*/
button {
  --webkit-appearance: none;
}

.button{
  display: inline-block;
  background-color: #4070F4;
  background-color: var(--first-color);
  color: #fff;
  padding: .75rem 2.5rem;
  font-weight: 600;
  font-weight: var(--font-semi);
  border-radius: .5rem;
}

.blog-button {
  display: inline-block;
  background-color: #4070F4;
  background-color: var(--first-color);
  color: #fff;
  padding: .5rem 1rem;
  margin-top: 0.5rem;
  font-weight: 600;
  font-weight: var(--font-semi);
  border-radius: .5rem;
}

.button:hover{
  box-shadow: 0 10px 36px rgba(0,0,0,.15);
}

/* ===== ABOUT =====*/
.about__container{
  grid-row-gap: 2rem;
  row-gap: 2rem;
  text-align: left;
}
.about__subtitle{
  margin-bottom: 1rem;
  margin-bottom: var(--mb-2);
}
.about__img{
  justify-self: center;
}
.about__img img{
  width: 200px;
  border-radius: .5rem;
}

/* ===== Skills =====*/

.flex-grid{
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.skill {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1.2rem;
  justify-content: flex-start;
  align-items: center;
}

.svgicon {
  max-width: 30%;
  margin-right: 1rem;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skill__title {
  font-weight: 600;
  font-size: 1rem;
}

.badges__container{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.badge {
  margin: 0.1rem 0.1rem;
  border-radius: 1em;
  padding: 0 0.5rem;
  text-align: center;
  background: #4070F4;
  background: var(--first-color);
  color: #fefefe;
}

/* ===== CONTACT =====*/

.contact__img{
  padding-bottom: 2em;
}

textarea {
  resize: none;
}

.contact__input{
  width: 100%;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  padding: 1rem;
  border-radius: .5rem;
  border: 1.5px solid #0E2431;
  border: 1.5px solid var(--second-color);
  outline: none;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}

.button__contact_container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact__button{
  border: none;
  outline: none;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  cursor: pointer;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

input:focus::-ms-input-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}

/* ===== BLOG ===== */

.post {
  margin: 5%;
}

.post h1,h2,h3,h4 {
  margin: 0.5rem 0;
}

.post ul {
  list-style:circle !important;
  margin: 0.5rem;
}

.postBlockContent p {
  padding-top: 2em;
  text-align: justify;
  text-justify: inter-word;
}

.postBlockContent a {
  color: #4070F4;
}

.postBlockContent figure {
  display: flex;
  justify-content: center;
  padding: 1em;
}

.postBlockContent figure img {
  width: 100%;
  height: 100%;
  padding: 1em;
}


/* ===== FOOTER =====*/
.footer{
  background-color: #0E2431;
  background-color: var(--second-color);
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-weight: var(--font-semi);
  padding: 2rem 0;
  position: relative;
}
.footer__title{
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}
.footer__social{
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}
.footer__icon{
  font-size: 1.5rem;
  color: #fff;
  margin: 0 1rem;
  margin: 0 var(--mb-2)
}

/* ===== MEDIA QUERIES=====*/
@media screen and (min-width: 768px){
  body{
    margin: 0;
  }
  .section{
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .section-title{
    margin-bottom: 3rem;
    margin-bottom: var(--mb-6);
  }
  .section-title::after{
    width: 80px;
    top: 3rem;
  }

  .nav{
    height: calc(3rem + 1rem);
    height: calc(var(--header-height) + 1rem);
  }
  .nav__list{
    display: flex;
    padding-top: 0;
  }
  .nav__item{
    margin-left: 3rem;
    margin-left: var(--mb-6);
    margin-bottom: 0;
  }
  .nav__toggle{
    display: none;
  }
  .nav__link{
    color: #0E2431;
    color: var(--second-color);
  }

  .home{
    height: 100vh;
  }

  .home__data{
    align-self: flex-end;
  }
  .home__social{
    padding-top: 0;
    padding-bottom: 2.5rem;
    flex-direction: row;
    align-self: flex-end;
  }
  .home__social-icon{
    margin-bottom: 0;
    margin-right: 2rem;
    margin-right: var(--mb-4);
  }
  .home__img{
    width: 457px;
    bottom: 15%;
  }

  .about__container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    text-align: left;
    text-align: initial;
  }
  .about__img img{
    width: 300px;
  }

  .flex-grid {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .skill{
    width: 50%;
  }


  .contact__container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    text-align: left;
    text-align: initial;
  }


  .post-card {
    padding: 2rem 0;
    width: 35%;
  }
  
  .post {
    margin-top: 4rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (max-width: 1200px){
  .home__img {
    bottom: 2%;
  }

  .divider {
    display: inline;
    display: initial;
  }
}

@media all and (max-width: 992px) and (max-height: 600px) and (orientation:landscape) {
  .home{
    padding-top: 4rem;
    margin-bottom: 5rem;
  }
  .home__img{
    width: 45%;
    top: 4.2rem;
  }

  .home__social{
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px){
  .bd-grid{
    margin-left: auto;
    margin-right: auto;
  }
  .home__img{
    right: 10%;
  }
}

body::-webkit-scrollbar {
  width: 1em;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.burguer div{
  width: 25px;
  height: 3px;
  background-color: #0E2431;
  background-color: var(--second-color);
  margin: 5px;
  transition: all 0.3s ease;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {

  transform: rotate(45deg) translate(-5px, -6px);
}


.blog__post{
    width: 100%;
    max-width: 49rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: .8rem;
    margin-bottom: 1.5rem;
}

.blog__post__img {
    min-width: 16rem;
    max-width: 16rem;
    height: 16rem;
    transform: translateX(-4rem);
    position: relative;
}

.blog__post__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: .8rem;
}

.blog__post__img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;                                   
    box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
    border-radius: .7rem;
}

.blog__post__date span {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    margin: .2rem 0;
}

.blog__post__title {
    font-size: 1.3rem;
    margin: 0.5rem 0 1rem;
    text-transform: uppercase;
    color: #4facfe;
}

.blog__post__text{
    margin-bottom: 2rem;
    font-size: 1rem;
}

.blog__post__cta {
    display: inline-block;
    padding: .75rem 1.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #fff;
    background-color: #4070F4;
    border-radius: .8rem;
}

@media screen and (max-width: 1068px){
    .blog__post{
        max-width: 40rem;
    }
    .blog__post__img{
        min-width: 15rem;
        max-width: 15rem;
    }
}

@media screen and (max-width: 868px){
    .blog__post{
        margin-top: 2rem;
        padding: 2rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .blog__post__img{
        min-width: 100%;
        max-width: 100%;
        transform: translate(0, -4rem);
    }

    .blog__post__title {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 768px){
    .blog__post{
        padding: 2rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .blog__post__img{
        min-width: 100%;
        max-width: 100%;
        transform: translate(0, -4rem);
    }
}
