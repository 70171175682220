.blog__post{
    width: 100%;
    max-width: 49rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: .8rem;
    margin-bottom: 1.5rem;
}

.blog__post__img {
    min-width: 16rem;
    max-width: 16rem;
    height: 16rem;
    transform: translateX(-4rem);
    position: relative;
}

.blog__post__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: .8rem;
}

.blog__post__img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;                                   
    box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
    border-radius: .7rem;
}

.blog__post__date span {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    margin: .2rem 0;
}

.blog__post__title {
    font-size: 1.3rem;
    margin: 0.5rem 0 1rem;
    text-transform: uppercase;
    color: #4facfe;
}

.blog__post__text{
    margin-bottom: 2rem;
    font-size: 1rem;
}

.blog__post__cta {
    display: inline-block;
    padding: .75rem 1.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #fff;
    background-color: #4070F4;
    border-radius: .8rem;
}

@media screen and (max-width: 1068px){
    .blog__post{
        max-width: 40rem;
    }
    .blog__post__img{
        min-width: 15rem;
        max-width: 15rem;
    }
}

@media screen and (max-width: 868px){
    .blog__post{
        margin-top: 2rem;
        padding: 2rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .blog__post__img{
        min-width: 100%;
        max-width: 100%;
        transform: translate(0, -4rem);
    }

    .blog__post__title {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 768px){
    .blog__post{
        padding: 2rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .blog__post__img{
        min-width: 100%;
        max-width: 100%;
        transform: translate(0, -4rem);
    }
}